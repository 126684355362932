import { Button, Form, Input, DatePicker, Modal, Checkbox } from "antd";
import freestyle from "../assets/image/freestyle_logo.png";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import tick1 from "../assets/image/tick1.png";
import { connect } from 'react-redux';

const UploadPage = ({ user, token, storecode, userLogin, updateTransaction }) => {
  const navigate = useNavigate();
  const [showButtonClick, setShowButtonClick] = useState(true);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [error, setError] = useState("");

  const [filetype, setFiletype] = useState("");
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [loading, setLoading] = useState(false);
  const [uri, setUri] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [receiptNoError, setReceiptNoError] = useState("");
  const [uriError, setUriError] = useState("");

  // const [quantity, setQuantity] = useState("");
  // const [quantityError, setQuantityError] = useState("");

  const [address1, setAddress1] = useState("");

  const [addressError, setAddressError] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const uploadimage = useRef();

  useEffect(() => {
    if (!user) {
      if (token) {
        setLoading(true);
        axios
          .post("/api/auth/checkToken", { token })
          .then((data) => {
            userLogin({ user: data.data.user, token: data.data.token })
            if (!data.data.user.address) {
              navigate("/welcome")
            }
            setLoading(false);
          })
          .catch((err) => {
            navigate("/welcome")
          });
      } else {
        navigate("/welcome")
      }
    } else {
      if (!user.address) {
        navigate("/welcome")
      }
    }
  }, [])

  const isReceiptDate = (date, dateString) => {
    setDate(dateString);
  };

  const onButtonClick = () => {
    uploadimage.current.click();
  };

  const disabledDate = (current) => {
    return current && current.valueOf() > Date.now();
  };

  const handleImage = (e) => {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        e.target.files[0],
        1000,
        1000,
        "PNG",
        30,
        0,
        (uri) => {
          setUri(uri);
          setFiletype(e.target.files[0].type);
        }
      );
    }
  };

  const isAmount = (e) => {
    setAmount(e.target.value);

    let regNumber = /^\d{0,8}(\.\d{0,2})?$/;
    if (!regNumber.test(e.target.value)) {
      setAmountError(
        'Invalid price format. Supported price format is "XX.XX" OR "XX"'
      );
    } else {
      setAmountError("");
    }
    if (!e.target.value) {
      setAmountError("");
    }
  };

  const onChange = (e) => {
    if (e.target.checked) {
      setAddress1(user.address);
    } else {
      setAddress1("");
    }
  };

  const onSubmit = () => {
    setLoading(true);
    setReceiptNoError("");
    setDateError("");
    setAmountError("");
    setUriError("");
    setAddressError("");
    //setQuantityError("");

    let regNumber = /^\d{0,8}(\.\d{0,2})?$/;

    if (!receiptNo) {
      setReceiptNoError("Please enter your Receipt No.");
      setLoading(false);
    } else if (!date) {
      setDateError("Please select a Receipt Date.");
      setLoading(false);
    } else if (!amount) {
      setAmountError("Please enter your Receipt Amount.");
      setLoading(false);
    } else if (!uri) {
      setUriError("Please insert an image");
      setLoading(false);
    } else if (!address1) {
      setAddressError("Please enter your address");
      setLoading(false);
    }
    // else if (!quantity) {
    //   setQuantityError("Please enter your quantity purchased.");
    //   setLoading(false);
    // } 
    else if (!regNumber.test(amount)) {
      setAmountError('Invalid price format. Supported price format is "XX.XX" OR "XX"');
      setLoading(false);
    } else {

      axios
        .post("/api/trans/recordTrans", {
          number: user.number,
          filetype,
          receiptNum: receiptNo,
          receiptDate: date,
          amount,
          uri,
          address: address1,
          //quantity,
          storecode
        })
        .then((res) => {
          updateTransaction(res.data.data);
          setLoading(false);
          setShowButtonClick(false);
          setModalSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.error);
        });
    }
  };

  const doneUpload = () => {
    if (storecode && storecode.includes("FSLPhy")) {
      navigate("/redemption", { state: { redeem: true } })
    } else {
      navigate("/welcome")
    }
  }

  return (
    <div>
      {" "}
      <Form onFinish={onSubmit}>
        <div className="header-icon-position">
          <img src={freestyle} alt="" className="header-icon-size" />
        </div>
        <div className="header-upload">
          {storecode && storecode.includes("FSLPhy") ? "Buy a Starter Kit now and attend the Education Session to get a free sensor" : "Buy a Starter Kit now and attend the Education Session to get a free sensor"}
        </div>
        <div className="strechable">
          <div>
            <div className="upload-input-title">Upload Image</div>
            <div className="upload-container">
              <div onClick={onButtonClick}>
                <input
                  onChange={handleImage}
                  type="file"
                  accept="image/*"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  ref={uploadimage}
                  hidden
                />
                {uri ? (
                  <div className="uploadimage-box-uploaded">
                    <img
                      style={{ width: "100%", height: "auto" }}
                      src={uri}
                      alt=""
                    />
                  </div>
                ) : (
                  <div>
                    {showButtonClick ? (
                      <div className="uploadimage-box">
                        <button className="uploadimage-box-font" type="button">
                          Upload receipt photo
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="errormessage-new">{uriError ? uriError : null}</div>
          </div>
          <div>
            <div className="upload-white-title">RECEIPT DETAILS</div>
          </div>
          <div>
            <div className="upload-input-title">Receipt No</div>
            <Input
              className="upload-input-box"
              required
              onChange={(e) => setReceiptNo(e.target.value)}
            ></Input>
            <div className="errormessage-new">
              {receiptNoError ? receiptNoError : null}
            </div>
          </div>
          <div>
            <div className="upload-input-title">Receipt Date</div>
            <DatePicker
              className="upload-input-box"
              required
              disabledDate={disabledDate}
              onChange={isReceiptDate}
            ></DatePicker>{" "}
            <div className="errormessage-new">
              {dateError ? dateError : null}
            </div>
          </div>
          <div>
            <div className="upload-input-title">Receipt Amount</div>
            <Input
              className="upload-input-box"
              type="number"
              min={0}
              onChange={(e) => isAmount(e)}
              value={amount}
              step="any"
              required
              onWheel={(e) => e.target.blur()}
            ></Input>
            <div className="errormessage-new">
              {amountError ? amountError : null}
            </div>
          </div>
          {/* <div>
            <div className="upload-input-title">Quantity Purchased</div>
            <Input
              className="upload-input-box"
              type="number"
              min={0}
              onChange={(e) => setQuantity(e.target.value)}
              value={quantity}
              step="1"
              required
              onWheel={(e) => e.target.blur()}
            ></Input>
            <div className="errormessage-new">
              {quantityError ? quantityError : null}
            </div>
          </div> */}
          <div>
            <div className="upload-input-title">Address</div>
            <Input
              className="upload-input-box"
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            ></Input>
            <Checkbox
              className="upload-checkbox"
              onChange={onChange}
            >
              <div className="upload-input-title-checkbox">
                Use your registered address?
              </div>
            </Checkbox>

            <div className="errormessage-new">
              {addressError ? addressError : null}
            </div>
          </div>
          <div className="button-container">
            <div className="errormessage-new">{error ? error : null}</div>
            <Button
              className="ok-button-three"
              loading={loading}
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        open={modalSuccess}
        centered
        header={null}
        footer={null}
        closable={null}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={tick1}
            style={{
              marginBottom: "5vh",
              width: "100px",
              height: "100px",
            }}
            alt=""
          ></img>
          <h1 style={{ fontWeight: "bold" }}>Success!</h1>
          {
            storecode && storecode.includes("FSLPhy") ? (
              <div style={{ marginBottom: "3vh" }}>
                Your receipt has successfully been uploaded!
              </div>
            ) : (
              <div style={{ marginBottom: "3vh" }}>
                Your receipt has successfully been uploaded! Receipt will be
                validated within <b> 3 working days.</b> Once approved, the free sensor will be delivered within 30 days.
              </div>
            )
          }
          <Button
            className="ok-button-two"
            style={{ width: "50%" }}
            onClick={() => doneUpload()}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    storecode: state.storecode,
    user: state.user,
    token: state.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: data => dispatch({ type: "USER_LOGIN", payload: data }),
    updateTransaction: data => dispatch({ type: "UPDATE_TRANSACTION", payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage);
